<template>
  <div class="zs-pricing">
    <div class="box">
      <div class="columns">
        <div class="column has-text-centered">
          <b-field class="has-text-left is-inline-block" label="Plan Duration">
            <p class="control">
              <b-button
                :type="switchYear ? '' : 'is-info'"
                @click="switchYear = false"
                rounded
                >Single Event</b-button
              >
            </p>
            <p class="control">
              <b-button
                :type="switchYear ? 'is-info' : ''"
                rounded
                @click="switchYear = true"
                >Annual Use</b-button
              >
            </p>
          </b-field>
        </div>
        <div class="column has-text-centered">
          <b-field class="has-text-left is-inline-block" label="Game Types">
            <b-dropdown v-model="selectedTypes" aria-role="list">
              <template #trigger>
                <b-button icon-right="menu-down" class="px-3 pt-2" rounded>
                  <div class="media mt-1">
                    <figure class="media-left mr-1">
                      <p
                        class="image is-24x24 is-inline-block mr-1"
                        v-if="selectedTypes.includes('beergame')"
                      >
                        <img :src="gameTypes['beergame'].logo" />
                      </p>
                      <p
                        class="image is-24x24 is-inline-block mr-1"
                        v-if="selectedTypes.includes('leangame')"
                      >
                        <img :src="gameTypes['leangame'].logo" />
                      </p>
                    </figure>
                    <div class="media-content">
                      {{
                        gameTypes[selectedTypes]
                          ? gameTypes[selectedTypes].name
                          : "Both Games"
                      }}
                    </div>
                  </div>
                </b-button>
              </template>

              <b-dropdown-item value="beergame" aria-role="listitem">
                <div class="media my-1">
                  <figure class="media-left mr-1">
                    <p class="image is-32x32">
                      <img :src="gameTypes['beergame'].logo" />
                    </p>
                  </figure>
                  <div class="media-content is-size-5 pl-2">
                    Beer Game
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item value="leangame" aria-role="listitem">
                <div class="media my-1">
                  <figure class="media-left mr-1">
                    <p class="image is-32x32">
                      <img :src="gameTypes['leangame'].logo" />
                    </p>
                  </figure>
                  <div class="media-content is-size-5 pl-2">
                    Lean Game
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item value="beergame-leangame" aria-role="listitem">
                <div class="media my-1">
                  <figure class="media-left mr-1">
                    <p class="image is-32x32 is-inline-block mr-2">
                      <img :src="gameTypes['beergame'].logo" />
                    </p>
                    <p class="image is-32x32 is-inline-block">
                      <img :src="gameTypes['leangame'].logo" />
                    </p>
                  </figure>
                  <div class="media-content is-size-5 pl-2">
                    Both Games
                    <p class="is-size-7">
                      <b class="has-text-danger">-20% discount</b>
                    </p>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </div>
        <div class="column has-text-centered">
          <b-field
            class="is-inline-block has-text-left"
            label="Usage Type"
            v-if="!currentUser || !currentUser.profileType"
          >
            <b-select v-model="localProfileType">
              <option value="business">At work</option>
              <option value="education">At school or University</option>
            </b-select>
          </b-field>
          <b-field
            class="is-inline-block has-text-left"
            label="Usage type: Educational"
            v-if="
              currentUser &&
                currentUser.profileType &&
                currentUser.profileType !== 'business'
            "
          >
            <p class="control is-size-7">
              If you are using the app in a corporate setting, please
              <a
                class="has-text-primary has-text-weight-bold"
                @click="toggleProfileType"
                >update your profile to Business now</a
              >
            </p>
          </b-field>
          <b-field
            class="is-inline-block has-text-left"
            label="Usage type: Business"
            v-if="
              currentUser &&
                currentUser.profileType &&
                currentUser.profileType === 'business'
            "
          >
            <p class="control is-size-7">
              If you are using the app at school or university,
              <a
                class="has-text-primary has-text-weight-bold"
                @click="toggleProfileType"
                >update your profile now</a
              >
            </p>
          </b-field>
        </div>
      </div>
    </div>
    <div class="section" v-if="!switchYear">
      <div class="columns">
        <div class="column">
          <p>
            One-time payment for a single game experience, such as internal
            training sessions, corporate events and seminars.
          </p>
          <p class="label mt-3">How many participants do you expect?</p>
          <div class="columns is-mobile is-multiline">
            <div
              class="column is-6 pb-0"
              v-for="cp in confPlans"
              :key="`confplan-${cp.name}`"
            >
              <div
                class="box has-text-centered py-4 box-hover-btn"
                @click="custoConfParticipants = cp.fromParticipants"
              >
                <h4 class="title is-6">{{ cp.name }}</h4>
                <p class="subtitle is-6">
                  <span v-if="cp.fromParticipants >= 100">More than </span
                  >{{ cp.fromParticipants
                  }}<span v-if="cp.fromParticipants < 100"> participants</span>
                </p>
              </div>
            </div>
          </div>
          <b-field class="pt-2">
            <template slot="label">
              Customize to your needs:
              <span class="has-text-weight-normal has-text-grey is-size-6"
                >{{
                  custoConfParticipants >= 100
                    ? "Unlimited"
                    : custoConfParticipants
                }}
                Participants</span
              >
            </template>
            <div class="px-4 pt-3">
              <b-slider
                :min="4"
                :max="100"
                v-model="custoConfParticipants"
                size="is-medium"
                :custom-formatter="val => (val >= 100 ? 'Unlimited' : `${val}`)"
                :bigger-slider-focus="true"
                :type="`is-${planAvailTypes[selectedTypes].typeClass}`"
                rounded
              >
                <template v-for="val in [4, 20, 40, 60, 80, 100]">
                  <b-slider-tick :value="val" :key="val">{{
                    val >= 100 ? "Unlimited" : `${val}`
                  }}</b-slider-tick>
                </template>
              </b-slider>
            </div>
          </b-field>
        </div>
        <div class="column is-narrow"></div>
        <div class="column">
          <div
            class="pricing-card-wrapper"
            :class="{ [`${selectedTypes}-class`]: true }"
          >
            <div class="pricing-card card has-text-centered">
              <h3 class="title is-4" :class="{ 'mb-1': switchEdu }">
                Conference
                {{ custoConf.name }}
              </h3>
              <h3 class="title is-5" v-if="switchEdu">
                for Education
              </h3>
              <h3 class="subtitle is-6 mb-3">
                {{ planAvailTypes[selectedTypes].name }}
              </h3>
              <div class="has-text-centered">
                <div class="has-text-left is-inline-block">
                  <p class="mb-2">
                    <b-icon
                      class="has-text-success"
                      icon="check"
                      size="is-small"
                    ></b-icon>
                    <span v-if="custoConfParticipants < 100">
                      Up to {{ custoConfParticipants }}
                    </span>
                    <span v-if="custoConfParticipants >= 100"> Unlimited</span>
                    Participants
                  </p>
                  <p
                    class="mb-2"
                    v-for="ft in custoConf.features"
                    :key="`cpft-${ft}`"
                  >
                    <b-icon
                      class="has-text-success"
                      icon="check"
                      size="is-small"
                    ></b-icon>
                    {{ ft }}
                  </p>
                </div>
              </div>
              <div class="is-justify-self-end pt-3" v-if="quoteData">
                <p v-if="custoConf.disc" class="mb-1">
                  <span
                    style="text-decoration: line-through"
                    class="has-text-grey is-size-5"
                    >{{ quoteData.symbol }}{{ custoConf.prevCost }}</span
                  >
                  <span class="tag is-size-6 is-warning ml-3">
                    -{{ custoConf.disc }}%
                  </span>
                </p>
                <span
                  class="title is-2 is-relative"
                  :class="{
                    [`has-text-${planAvailTypes[selectedTypes].typeClass}`]: true
                  }"
                >
                  <span class="bg-plan-currency">{{ quoteData.symbol }}</span>
                  {{ custoConf.cost }}
                </span>
                <p
                  class="has-text-grey is-size-7"
                  v-if="quoteData.currency === 'eur'"
                >
                  excl. tax
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section" v-if="switchYear">
      <div class="columns">
        <div class="column">
          <p>
            Host as many game sessions as you need during one year. Perfect for
            recurring trainings, consultancy offers or multiple courses
            throughout the year.
          </p>
          <p class="mt-3" v-if="quoteData">
            The first instructor seat is priced at
            <b> {{ quoteData.symbol }}{{ custoAnnual.initCost / 12 }} </b> per
            month, additional seats add
            <b>{{ quoteData.symbol }}{{ custoAnnual.extraSeatCost / 12 }}</b>
            per instructor per month.
          </p>
          <b-field class="mt-5">
            <template slot="label">
              Number of instructors in your organization:
              <span class="has-text-weight-normal has-text-grey is-size-6"
                >{{
                  custoAnnualInstructors >= 10 ? "10+" : custoAnnualInstructors
                }}
              </span>
              <p
                class="has-text-weight-normal"
                v-if="custoAnnualInstructors >= 10"
              >
                For more than 10 instructors please contact us at
                <a href="mailto:hello@zensimu.com" target="_blank"
                  ><em>hello@zensimu.com</em></a
                >
              </p>
            </template>
            <div class="px-4 pt-3">
              <b-slider
                :min="1"
                :max="10"
                v-model="custoAnnualInstructors"
                size="is-medium"
                :custom-formatter="val => (val >= 10 ? '10+' : `${val}`)"
                :bigger-slider-focus="true"
                :type="`is-${planAvailTypes[selectedTypes].typeClass}`"
                rounded
              >
                <template v-for="val in [1, 5, 10]">
                  <b-slider-tick :value="val" :key="val">{{
                    val >= 10 ? "10+" : `${val}`
                  }}</b-slider-tick>
                </template>
              </b-slider>
            </div>
          </b-field>
        </div>
        <div class="column is-narrow"></div>
        <div class="column">
          <div
            class="pricing-card-wrapper"
            :class="{ [`${selectedTypes}-class`]: true }"
          >
            <div class="pricing-card card has-text-centered">
              <h3 class="title is-4" :class="{ 'mb-1': switchEdu }">
                Annual Plan
              </h3>
              <span class="title is-5" v-if="switchEdu">
                for Education
              </span>
              <h3 class="subtitle is-6 mb-3">
                {{ planAvailTypes[selectedTypes].name }}
              </h3>
              <div class="has-text-centered">
                <div class="has-text-left is-inline-block">
                  <p class="mb-2">
                    <b-icon
                      class="has-text-success"
                      icon="check"
                      size="is-small"
                    ></b-icon>
                    Unlimited Participants
                  </p>
                  <p
                    class="mb-2"
                    v-for="ft in custoAnnual.features"
                    :key="`cpft-${ft}`"
                  >
                    <b-icon
                      class="has-text-success"
                      icon="check"
                      size="is-small"
                    ></b-icon>
                    {{ ft }}
                  </p>
                </div>
              </div>
              <div class="is-justify-self-end pt-3" v-if="quoteData">
                <p v-if="custoAnnual.disc" class="mb-1">
                  <span
                    style="text-decoration: line-through"
                    class="has-text-grey is-size-5"
                    >{{ quoteData.symbol
                    }}{{ custoAnnual.prevCost / 12 }}/month</span
                  >
                  <span class="tag is-size-6 is-warning ml-3">
                    -{{ custoAnnual.disc }}%
                  </span>
                </p>
                <span
                  class="title is-2 is-relative"
                  :class="{
                    [`has-text-${planAvailTypes[selectedTypes].typeClass}`]: true
                  }"
                >
                  <span class="bg-plan-currency">{{ quoteData.symbol }}</span>
                  {{ custoAnnual.cost / 12
                  }}<span class="bg-plan-permonth">/month</span>
                </span>
                <p class="has-text-grey is-size-7">
                  {{ quoteData.symbol }}{{ custoAnnual.cost }} billed
                  annually<span v-if="quoteData.currency === 'eur'"
                    >, excl. tax</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import plansZS from "@/../functions/plansZS.json";

export default {
  name: "zsPricing",
  computed: {
    ...mapGetters([
      "authUser",
      "gameTypes",
      "teamAccount",
      "quoteData",
      "currentUser"
    ]),
    switchEdu() {
      if (this.currentUser && this.currentUser.profileType) {
        return this.currentUser.profileType !== "business";
      } else {
        return this.localProfileType !== "business";
      }
    },
    confPlans() {
      return plansZS["conference"];
    },
    custoConf() {
      let obj = {
        ...this.confPlans[0]
      };
      plansZS["conference"].forEach((pp, ii) => {
        if (this.custoConfParticipants >= pp.fromParticipants) {
          if (this.selectedTypes && this.quoteData) {
            obj = { ...pp, prevCost: 0, cost: 0 };
            this.selectedTypes.split("-").forEach(sgt => {
              let unitPrice = 0;
              let discUnitPrice = 0;
              let discountMultiple =
                (1 - (this.switchEdu ? pp.eduDiscount : 0)) *
                (1 - (this.selectedTypes.includes("-") ? pp.packDiscount : 0));
              if (ii < plansZS["conference"].length - 1) {
                let nextPlan = plansZS["conference"][ii + 1];
                let nextDiscountMultiple =
                  (1 - (this.switchEdu ? nextPlan.eduDiscount : 0)) *
                  (1 -
                    (this.selectedTypes.includes("-")
                      ? nextPlan.packDiscount
                      : 0));
                unitPrice =
                  (nextPlan.price[sgt][this.quoteData.currency] -
                    pp.price[sgt][this.quoteData.currency]) /
                  (nextPlan.fromParticipants - pp.fromParticipants);
                discUnitPrice =
                  (nextDiscountMultiple *
                    nextPlan.price[sgt][this.quoteData.currency] -
                    discountMultiple * pp.price[sgt][this.quoteData.currency]) /
                  (nextPlan.fromParticipants - pp.fromParticipants);
              }
              obj.prevCost += Math.round(
                pp.price[sgt][this.quoteData.currency] +
                  (this.custoConfParticipants - pp.fromParticipants) * unitPrice
              );
              obj.cost += Math.round(
                discountMultiple * pp.price[sgt][this.quoteData.currency] +
                  (this.custoConfParticipants - pp.fromParticipants) *
                    discUnitPrice
              );
            });
          }
        }
      });
      return {
        ...obj,
        title: `Conference ${obj.name}${
          this.switchEdu ? " for Education" : ""
        } - ${this.planAvailTypes[this.selectedTypes].name} - ${
          this.custoConfParticipants >= 100
            ? "Unlimited"
            : this.custoConfParticipants
        } Participants`,
        disc: Math.round((100 * (obj.prevCost - obj.cost)) / obj.prevCost)
      };
    },
    custoAnnual() {
      let obj = {
        ...plansZS["annual"][0],
        prevInitCost: 0,
        prevExtraSeatCost: 0,
        prevCost: 0,
        initCost: 0,
        extraSeatCost: 0,
        cost: 0
      };
      let discountMultiple =
        (1 - (this.switchEdu ? obj.eduDiscount : 0)) *
        (1 - (this.selectedTypes.includes("-") ? obj.packDiscount : 0));
      this.selectedTypes.split("-").forEach(sgt => {
        obj.prevInitCost += obj.price[sgt][this.quoteData.currency];
        obj.prevExtraSeatCost +=
          obj.extraSeatPrice[sgt][this.quoteData.currency];
        obj.prevCost =
          obj.prevInitCost +
          (this.custoAnnualInstructors - 1) * obj.prevExtraSeatCost;
        obj.initCost = discountMultiple * obj.prevInitCost;
        obj.extraSeatCost = discountMultiple * obj.prevExtraSeatCost;
        obj.cost = discountMultiple * obj.prevCost;
      });
      return {
        ...obj,
        disc: Math.round(100 * (1 - discountMultiple)),
        title: `Annual Plan${this.switchEdu ? " for Education" : ""} - ${
          this.planAvailTypes[this.selectedTypes].name
        } - ${this.custoAnnualInstructors} Instructor${
          this.custoAnnualInstructors > 1 ? "s" : ""
        }`
      };
    },
    accountStatus() {
      let obj = {
        canPurchase: this.authUser && this.authUser.email,
        message: null
      };
      if (
        this.teamAccount &&
        (!this.teamAccount.members ||
          !this.teamAccount.members[this.authUser.uid] ||
          this.teamAccount.members[this.authUser.uid].role !== "admin")
      ) {
        obj = {
          canPurchase: false,
          message:
            "You don't have the rights to change your account plans. Please contact the admin of your team."
        };
      }
      Object.values(this.selectedTypes).forEach(gt => {
        if (
          this.teamAccount &&
          this.teamAccount.plans &&
          this.teamAccount.plans[gt] &&
          this.teamAccount.plans[gt].active
        ) {
          obj = {
            canPurchase: false,
            message: `Your account already has an active license for ${
              this.selectedTypes.length > 1
                ? "one of the selected games"
                : "this game"
            }`
          };
        }
      });
      return obj;
    }
  },
  created() {
    if (!this.quoteData) {
      this.$store.dispatch("updateQuoteData");
    }
  },
  methods: {
    toggleProfileType() {
      let newType =
        this.currentUser.profileType !== "business" ? "business" : "education";
      this.$buefy.dialog.confirm({
        message: `Update your profile type to <b>${newType}</b> ?${
          newType === "education"
            ? "<br>Your account must use your university email."
            : ""
        }`,
        cancelText: "Cancel",
        size: "is-medium",
        confirmText: "Update",
        onConfirm: () => {
          this.$store
            .dispatch("updateUserProfile", {
              uid: this.currentUser.uid,
              profileType: newType
            })
            .then(() => {
              this.$buefy.toast.open("Profile updated");
            });
        }
      });
    }
  },
  data() {
    return {
      custoConfParticipants: 20,
      custoAnnualInstructors: 1,
      switchYear: true,
      localProfileType: "business",
      selectedTypes: "beergame",
      planAvailTypes: {
        beergame: {
          name: "Beer Game",
          typeClass: "primary"
        },
        leangame: {
          name: "Lean Game",
          typeClass: "success"
        },
        [`beergame-leangame`]: {
          name: "Beer Game + Lean Game",
          typeClass: "dark"
        }
      }
    };
  }
};
</script>
<style lang="scss">
.zs-pricing {
  .bg-plan-currency {
    position: absolute;
    right: 100%;
    top: 5px;
    color: hsl(0, 0%, 4%);
    font-size: 0.6em;
  }
  .bg-plan-permonth {
    position: absolute;
    word-break: keep-all;
    font-weight: normal;
    left: 100%;
    top: 10px;
    color: #666;
    font-size: 0.35em;
  }
  .b-slider-tick-label {
    margin-top: 5px;
    font-size: 1em !important;
  }
  .pricing-card-wrapper {
    height: 100%;
    border-radius: 10px;
    padding: 1em;
    .pricing-card {
      display: flex;
      flex-direction: column;
      .is-justify-self-end {
        margin-top: auto;
      }
      height: 100%;
      padding: 1.8em 1em;
      border-radius: 5px;
      background-color: hsl(0, 0%, 100%);
      transition: transform 300ms ease-out, box-shadow 300ms ease;
      &:hover {
        // transform: scale(1.05);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
}
</style>
