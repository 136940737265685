<template>
  <div class="quote-page has-background-white">
    <section class="section">
      <div class="container is-max-desktop pt-5">
        <div class="columns">
          <div class="column">
            <h1 class="title is-2">
              Quote
            </h1>
          </div>
          <div class="column has-text-centered is-narrow">
            <p class="mt-2">
              <router-link to="/dashboard/">
                <zensimu-logo
                  :show-title="true"
                  class="is-inline-block"
                  :logo-height="35"
                ></zensimu-logo>
              </router-link>
            </p>
          </div>
          <div class="column"></div>
        </div>
        <div class="columns mt-5 is-vcentered">
          <div class="column">
            <b-field
              label="Quote for:"
              v-if="!isPrinting || (companyInfo && companyInfo.length)"
            >
              <b-input
                v-if="!isPrinting"
                v-model="companyInfo"
                type="textarea"
                placeholder="Your organization name/address"
              ></b-input>
              <p class="is-size-5 has-text-grey" v-if="isPrinting">
                {{ companyInfo }}
              </p>
            </b-field>
          </div>
          <div class="column has-text-right has-text-grey">
            <p>Valid for: <b>30 days</b></p>
            <p>
              Issue date: <b>{{ issueDate }}</b>
            </p>
            <p>
              Expiration date: <b>{{ expDate }}</b>
            </p>
          </div>
        </div>

        <div class="center-quote mb-6">
          <zs-pricing></zs-pricing>
        </div>
        <div class="has-text-centered" v-if="!isPrinting">
          <b-button
            @click="printQuote"
            type="is-primary"
            size="is-large"
            rounded
            >Print Quote</b-button
          >
        </div>
        <div v-if="isPrinting">
          <div class="is-divider my-5"></div>
          <div class="columns">
            <div class="column">
              <b>Zensimu SAS </b><br />5 Avenue du General de Gaulle <br />94160
              SAINT-MANDE <br />FRANCE <br />EU VAT No. FR47883718835
            </div>
            <div class="column">
              <b>Bank information:</b>
              <br />IBAN : FR7616958000010604623873516 <br />SWIFT : QNTOFRP1XXX
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ZensimuLogo from "@/components/ZensimuLogo.vue";
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import ZsPricing from "@/components/billing/ZsPricing.vue";

export default {
  name: "Quote",
  components: { ZensimuLogo, ZsPricing },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    this.issueDate = new Date().toLocaleDateString();
    this.expDate = new Date();
    this.expDate.setDate(this.expDate.getDate() + 30);
    this.expDate = this.expDate.toLocaleDateString();
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    printQuote() {
      this.isPrinting = true;
      this.$nextTick(() => {
        window.print();
        this.$nextTick(() => {
          this.isPrinting = false;
        });
      });
    }
  },
  watch: {},
  validations: {
    leadEmail: {
      required,
      email
    }
  },
  data() {
    return {
      isPrinting: false,
      companyInfo: "",
      issueDate: null,
      expDate: null
    };
  }
};
</script>

<style lang="scss">
.quote-page {
  min-height: 100vh;
}
</style>
