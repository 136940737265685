<template>
  <div class="game-demo-page">
    <section class="section">
      <div class="container">
        <h1 class="title">
          Play a Demo of the
          {{
            selectType && selectType[0] === "beergame"
              ? "Beer Game"
              : selectType && selectType[0]
              ? "Lean Game"
              : ""
          }}
        </h1>
        <h1 class="subtitle mb-4">
          Quickly discover our simulations as a participant
        </h1>
        <div class="bg-intro">
          <article class="columns">
            <div class="column is-6-desktop is-narrow-touch">
              <b-notification
                :active="showError"
                @close="closeError"
                type="is-danger"
                >{{ showError ? error.message : "" }}</b-notification
              >
              <section class="box pt-3">
                <div class="pt-1 mb-5">
                  <b-field
                    label="Work Email* :"
                    class="mb-1"
                    :type="{ 'is-danger': $v.leadEmail.$error }"
                    :message="
                      !$v.leadEmail.$error
                        ? ''
                        : !$v.leadEmail.required
                        ? 'Email is required'
                        : 'Wrong email format'
                    "
                  >
                    <b-input
                      ref="focusemail"
                      v-model="$v.leadEmail.$model"
                      size="is-medium"
                      type="email"
                      placeholder="albus.dumbledore@hogwarts.edu"
                    ></b-input>
                  </b-field>
                  <p class="is-italic is-size-7">
                    We'll put you in the loop of our product updates. See our
                    Privacy Policy
                    <a href="https://zensimu.com/privacy/" target="_blank"
                      >here</a
                    >.
                  </p>
                  <b-field label="I am a.. *" class="mt-3">
                    <b-field expanded>
                      <b-radio-button
                        expanded
                        v-model="profileType"
                        native-value="education"
                      >
                        <b-icon icon="presentation" size="is-small"></b-icon>
                        <span>Professor</span>
                      </b-radio-button>
                      <b-radio-button
                        expanded
                        v-model="profileType"
                        native-value="student"
                      >
                        <b-icon icon="school" size="is-small"></b-icon>
                        <span>Student</span>
                      </b-radio-button>
                      <b-radio-button
                        expanded
                        v-model="profileType"
                        native-value="business"
                      >
                        <b-icon icon="briefcase" size="is-small"></b-icon>
                        <span>Professional</span>
                      </b-radio-button>
                    </b-field>
                  </b-field>
                </div>
                <div v-if="!confirmSetup">
                  <div
                    class="is-divider is-italic has-text-dark mt-1 mb-3"
                    data-content="Customize Demo :"
                  ></div>
                  <!-- <div class="has-text-centered is-italic">Customize Demo:</div> -->
                  <!-- <b-field label="Game type :" class="mb-2">
                    <b-field grouped>
                      <b-radio-button
                        v-model="selectType"
                        native-value="beergame"
                        type="is-primary is-light"
                        size="is-medium"
                      >
                        <b-icon icon="glass-mug"></b-icon>
                        <span>Beer Game</span>
                      </b-radio-button>
                      <b-radio-button
                        v-model="selectType"
                        native-value="leangame"
                        type="is-primary is-light"
                        size="is-medium"
                      >
                        <b-icon icon="autorenew"></b-icon>
                        <span>Lean Game</span>
                      </b-radio-button>
                    </b-field>
                  </b-field> -->
                  <select-game-type
                    v-model="selectType"
                    :show-plan="false"
                    :show-article="false"
                  ></select-game-type>

                  <b-field
                    v-if="selectType && selectType[0]"
                    label="Template :"
                    class="mb-1"
                  >
                    <b-field>
                      <dropdown-select-template
                        v-model="selectTemplate"
                        :type="selectType ? selectType[0] : null"
                      ></dropdown-select-template>
                      <p
                        class="control pl-4 has-text-grey"
                        v-if="selectTemplate"
                      >
                        {{ selectTemplate.description }}
                      </p>
                    </b-field>
                  </b-field>
                  <b-field
                    class="mb-1"
                    v-if="selectTemplate && selectTemplate.config"
                    label="Role :"
                  >
                    <b-dropdown
                      v-model="selectRole"
                      aria-role="list"
                      scrollable
                      ref="rolesdropdown"
                      :max-height="300"
                    >
                      <template #trigger>
                        <b-button
                          type="is-info is-light"
                          rounded
                          :icon-left="selectRole ? selectRole.icon : 'dice-3'"
                          icon-right="menu-down"
                          >{{
                            selectRole
                              ? selectRole.name
                              : "Random position" | capitalize
                          }}</b-button
                        >
                      </template>
                      <b-dropdown-item
                        key="random"
                        :value="null"
                        aria-role="listitem"
                        class="game-template-dropdown-item"
                      >
                        <div class="media">
                          <b-icon class="media-left" icon="dice-3"></b-icon>
                          <div class="media-content">
                            <h3 class="has-text-dark">Random position</h3>
                          </div>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item separator> </b-dropdown-item>
                      <b-dropdown-item
                        v-for="role in Object.values(
                          selectTemplate.config.facilities
                        )"
                        :key="role.id"
                        :value="role"
                        aria-role="listitem"
                        class="game-template-dropdown-item"
                      >
                        <div class="media">
                          <b-icon class="media-left" :icon="role.icon"></b-icon>
                          <div class="media-content">
                            <h3 class="has-text-dark">
                              {{ role.name | capitalize }}
                            </h3>
                          </div>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-field>
                  <div class="is-divider mt-4 mb-0"></div>
                </div>
                <div class="has-text-centered pb-2 pt-4">
                  <b-button
                    size="is-large"
                    type="is-info"
                    @click="clickCreateDemo"
                    rounded
                    :disabled="!selectTemplate || !selectTemplate.config"
                    >Start
                    {{
                      selectType && selectType[0] === "beergame"
                        ? "Beer Game"
                        : selectType && selectType[0]
                        ? "Lean Game"
                        : ""
                    }}
                    demo
                  </b-button>
                  <p class="mt-3 is-italic">
                    <a
                      v-if="confirmSetup"
                      @click="
                        () => {
                          confirmSetup = false;
                          selectType = null;
                        }
                      "
                      >Try another game</a
                    >
                  </p>
                </div>
              </section>
            </div>
            <div class="column is-6">
              <div class="is-hidden-mobile pt-5"></div>
              <testimonials></testimonials>
            </div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import DropdownSelectTemplate from "@/components/Dashboard/DropdownSelectTemplate.vue";
import Testimonials from "@/components/marketing/Testimonials.vue";
import SelectGameType from "@/components/Dashboard/SelectGameType.vue";

export default {
  name: "Game",
  components: {
    DropdownSelectTemplate,
    Testimonials,
    SelectGameType
  },
  computed: {
    ...mapGetters([
      "defaultTemplates",
      "authUser",
      "error",
      "showError",
      "loading"
    ])
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    clickCreateDemo() {
      this.$v.$touch();
      if (this.selectTemplate && !this.$v.$invalid) {
        // Select position
        let availPos = Object.values(
          this.selectTemplate.config.facilities
        ).filter(faci => {
          return !faci.user;
        });
        let choosePos = this.selectRole
          ? this.selectRole
          : availPos[Math.floor(Math.random() * availPos.length)];
        this.$store
          .dispatch("createDemoGame", {
            email: this.leadEmail,
            roleId: choosePos.id,
            profileType: this.profileType,
            obj: {
              name: `Demo - ${this.selectTemplate.title}`,
              players: [],
              week: 0,
              isDemo: true,
              custoLook: false,
              finished: false,
              archived: false,
              deleted: false,
              type: this.selectTemplate.type,
              ...this.selectTemplate.config,
              fromTemplate: this.selectTemplate.title
            }
          })
          .then(gameObj => {
            if (gameObj && gameObj.id) {
              this.$buefy.toast.open({
                duration: 5000,
                queue: false,
                message: `Starting demo as <b>${choosePos.name}</b>`
              });
              return this.$router.push(`/game/${gameObj.id}/play`);
            }
          });
      }
    }
  },
  mounted() {
    this.$refs.focusemail.focus();
    if (this.$route.query.template) {
      this.selectTemplate = {
        ...this.defaultTemplates.find(temp => {
          return temp.id === this.$route.query.template;
        })
      };
    }
    if (!this.selectTemplate || !this.selectTemplate.type) {
      this.selectTemplate = {
        ...this.defaultTemplates.find(temp => {
          return temp.id === "shortbg";
        })
      };
    }
    this.selectType = [this.selectTemplate.type];
    if (this.$route.query.join) {
      this.selectRole = this.selectTemplate.config.facilities[
        this.$route.query.join
      ];
    }
  },
  watch: {
    selectType(newVal) {
      if (
        (newVal && !this.selectTemplate) ||
        this.selectTemplate.type !== newVal[0]
      ) {
        this.selectTemplate = {
          ...this.defaultTemplates.find(temp => {
            return (
              (newVal[0] === "beergame" && temp.id === "shortbg") ||
              (newVal[0] === "leangame" && temp.id === "shortlean")
            );
          })
        };
        this.selectRole = null;
      }
    }
  },
  validations: {
    leadEmail: {
      required,
      email
    }
  },
  data() {
    return {
      confirmSetup: true,
      leadEmail: "",
      profileType: "education",
      selectType: null,
      selectTemplate: null,
      selectRole: null
    };
  }
};
</script>

<style lang="scss">
.game-demo-page {
}
</style>
