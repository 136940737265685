<template>
  <div class="auth-email-action-page columns">
    <div class="column">
      <section class="hero is-fullheight">
        <div class="hero-body">
          <div class="container section">
            <h2 class="title is-3 has-text-centered">
              {{ pageTitle }}
            </h2>
            <b-notification
              :active="showError"
              @close="closeError"
              type="is-danger"
              >{{ showError ? error.message : "" }}</b-notification
            >
            <form @submit="submit" class="mt-3">
              <b-field
                label="New Password"
                v-if="actionType == 'resetPassword' && !newPasswordSetup"
              >
                <b-input
                  type="password"
                  minlength="6"
                  v-model="newPassword"
                  password-reveal
                  :disabled="loading"
                ></b-input>
              </b-field>
              <b-field
                label="Confirm Password"
                v-if="actionType == 'resetPassword' && !newPasswordSetup"
              >
                <b-input
                  type="password"
                  v-model="confirmPassword"
                  password-reveal
                  :disabled="loading"
                ></b-input>
              </b-field>
              <p
                class="has-text-success"
                v-if="actionType == 'resetPassword' && newPasswordSetup"
              >
                Password updated succesfully
                <b-icon icon="check" size="is-small"></b-icon>
              </p>
              <b-field v-if="actionType == 'resetPassword'">
                <p class="control has-text-centered">
                  <br />
                  <button
                    type="submit"
                    class="button is-success is-medium"
                    :disabled="findError || loading"
                  >
                    {{ newPasswordSetup ? "Connect" : "Validate" }}
                  </button>
                </p>
              </b-field>
              <span v-if="actionType == 'recoverEmail'">
                Your account email was reverted back to {{ newEmail }}.
                <br />
                <router-link to="/dashboard">
                  For security matters you may want to modify your password
                  <b-icon icon="arrow-right" size="is-small"></b-icon>
                </router-link>
              </span>
              <span class="has-text-success" v-if="actionType == 'verifyEmail'">
                Your email was successfully verified
                <b-icon icon="check" size="is-small"></b-icon>
              </span>
            </form>
          </div>
        </div>
      </section>
    </div>
    <div
      class="column is-hidden-touch"
      :style="{
        'background-color': custoLook.bckColor,
        color: custoLook.textColor
      }"
    >
      <section class="hero is-fullheight">
        <div class="hero-head hero-body">
          <div class="container has-text-centered">
            <main-logo
              class="mb-20"
              show-title="true"
              :custo-look="custoLook"
              noclick="true"
            ></main-logo>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainLogo from "@/components/MainLogo.vue";

export default {
  name: "authEmailAction",
  components: { MainLogo },
  computed: {
    ...mapGetters(["user", "loading", "error", "showError", "custoLook"]),
    pageTitle() {
      switch (this.actionType) {
        case "resetPassword":
          return "Password reset for " + this.newEmail;
        case "recoverEmail":
          return "Email recovery";
        case "verifyEmail":
          return "Email verification";
        default:
          return "Email verification";
      }
    },
    findError() {
      return (
        this.actionType == "resetPassword" &&
        (this.newPassword.length < 6 ||
          this.newPassword != this.confirmPassword)
      );
    }
  },
  created() {
    let qMode = this.$route.query.mode;
    if (qMode == "recoverEmail" || qMode == "verifyEmail") {
      this.$store
        .dispatch("updateEmailValidation", this.$route.query)
        .then(actionInfo => {
          this.newEmail = actionInfo["data"]["email"];
          this.actionType = qMode;
        });
    } else if (qMode == "resetPassword") {
      this.$store
        .dispatch("verifyPasswordResetLink", this.$route.query)
        .then(email => {
          this.newEmail = email;
          this.actionType = qMode;
        });
    } else {
      this.$store.commit("setError", {
        message:
          "The action link is invalid. It may have expired, or has already been used."
      });
    }
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    submit(e) {
      if (this.actionType == "resetPassword") {
        if (!this.newPasswordSetup && !this.findError) {
          this.$store
            .dispatch("resetPassword", {
              oobCode: this.$route.query.oobCode,
              newPassword: this.newPassword
            })
            .then(() => {
              this.newPasswordSetup = true;
            });
        } else if (this.newPasswordSetup) {
          this.$store.dispatch("login", {
            email: this.newEmail,
            password: this.newPassword,
            router: this.$router
          });
        } else {
          this.$store.commit("setError", {
            message: "The new password should be at least 6 characters long."
          });
        }
      }
      e.preventDefault();
    }
  },
  data() {
    return {
      actionType: false,
      newEmail: false,
      newPasswordSetup: false,
      newPassword: "",
      confirmPassword: ""
    };
  }
};
</script>
